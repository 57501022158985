import React, {useState} from 'react';
import classes from '../../AddVouchers.module.scss'
import Input from "../../../../../../submodules/naoo-web-components/Components/Input/Input";
import {TextButton} from "../../../../../../submodules/naoo-web-components/Components/Button/Buttons";
import Dropzone from "../../../../../../submodules/naoo-web-components/Components/Dropzone/Dropzone";

const EditMode = ({voucher, onChange}) => {

    const [editableVoucher, setEditableVoucher] = useState(voucher)

    const handleSaveClick = () => {
        editableVoucher.editMode = false
        onChange(editableVoucher)
    }

    const handleDropzoneChange = (files) => {
        setEditableVoucher(prev => ({
            ...prev,
            media: URL.createObjectURL(files[0])
        }))
    }

    const handleDropzoneClear = () => {
        setEditableVoucher(prev => ({
            ...prev,
            media: null
        }))
    }

    const handleIdChange = (event) => {
        setEditableVoucher(prev => ({
            ...prev,
            crmId: event.target.value
        }))
    }

    return (
        <>
            <Dropzone
                media={editableVoucher?.media}
                onChange={handleDropzoneChange}
                className={classes.Media}
                onClear={handleDropzoneClear}
                label={'Drag image or PDF here'}
            />

            <div className={classes.EditCrm}>
                <Input
                    placeholder={'Enter ID in CRM (optional)'}
                    value={editableVoucher?.crmId}
                    onChange={handleIdChange}
                />

                <TextButton
                    className={classes.SaveButton}
                    disabled={!editableVoucher?.media}
                    onClick={handleSaveClick}
                >
                    Save
                </TextButton>

            </div>
        </>
    );
};

export default EditMode;