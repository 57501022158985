import React from 'react';
import classes from "./Preview.module.scss";
import {useSelector} from "react-redux";
import {selectCurrentBusiness} from "../../../../../store/reducers/managedBusinessesSlice";
import {VOUCHER_TYPES} from "../VoucherSampleForm";
import Barcode from './assets/barcode.jpg';
import Qr from './assets/qr.jpg';

const Preview = ({media, title, voucherType}) => {
    const business = useSelector(selectCurrentBusiness)
    return (
        <div className={classes.Container}>
            <div className={classes.ImgContainer}>
                {
                    media && <img className={classes.Img} src={media?.url} alt=""/>
                }
            </div>
            <div className={classes.Info}>
                <div className={classes.Business}>
                    <div className={classes.Logo}>
                        <img src={business?.logo?.url} alt=""/>
                    </div>
                    <div className={classes.Name}>
                        <span>{business?.name}</span>
                    </div>
                </div>
                <div className={classes.Title}>
                    <span>
                        {title ? title : '____________________'}
                    </span>
                </div>
            </div>
            {
                voucherType === VOUCHER_TYPES.Barcode &&
                <div className={classes.Barcode}>
                    <img src={Barcode} alt=""/>
                </div>
            }
            {
                voucherType === VOUCHER_TYPES["QR code"] &&
                <div className={classes.QrCode}>
                    <img src={Qr} alt=""/>
                </div>
            }
        </div>
    );
};

export default Preview;