export const ACTIVE_MODULE_NAME = {
    DASHBOARD: 'dashboard',
    CREATE_OFFER: 'create-offer',
    EDIT_OFFER: 'edit-offer'
}

export const OFFER_STATUS = {
    IDLE: 'idle',
    CHANGED: 'CHANGED',
    LOADING:'loading',
    SUCCESSED:'successed',
    FAILED:'failed'
}

export const ROUTES = {
    MAIN:'/',
    AUTH:{
        AUTH:'/auth',
        SIGN_UP:'/signUp',
        FORGOT_PASSWORD:'/forgotPassword',
        LOGOUT:'/logout',
    },
    BUSINESS_DASHBOARD:{
        CREATE_BUSINESS:'/createbusiness',
        BUSINESS:'/business',
    },
    ADMIN_DASHBOARD:{
        BUSINESSES:'/businesses',
        POLLS: '/polls',
        POLL:'/poll/',
        USERS: '/users',
        NOTIFICATIONS: '/notifications',
        CASH_REDEMPTIONS: '/cashRedemptions',
        POINTS_TRANSACTIONS: '/points_transactions',
        COMPETITIONS: '/competitions',
        POSTS:'/posts',
        OFFERS:'/offers',
        PROMOTIONS:'/promotions',
        TAGS:'/tags',
        SHARES:'/shares',
        CHANNELS:'/channels',
        REPORTS: '/reports',
        VOUCHERS: '/vouchers'
    }
}

export const MEDIA_BREAKPOINTS = {
    XS: 0,
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1200,
    XXL: 1400
}
