import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    addCategory,
    addProfileMediaThunk,
    deleteProfileMediaThunk,
    fetchBusiness,
    fetchCategories,
    removeCategory,
    selectBusiness,
    selectCategories,
    setAddress,
    setCoordinates,
    setCover,
    setDescription,
    setEmail,
    setHours,
    setLogo,
    setLogoDimensions,
    setName,
    setPhone,
    setWebsite,
    updateBusiness
} from "../../../store/reducers/businessSlice";
import {
    fetchManagedBusinesses,
    selectCurrentBusinessId,
    setCurrentBusinessAddress,
    setCurrentBusinessLogo,
    setCurrentBusinessName
} from "../../../store/reducers/managedBusinessesSlice";
import {isValidWebsite} from "../../shared/utility/websiteValidation";
import {isValidPhoneNumber} from "../../shared/utility/phoneNumberValidation";
import {isValidEmail} from "../../shared/utility/emailValidation";
import classes from './EditBusiness.module.scss'
import BusinessPreview from "./UI/BusinessPreview/BusinessPreview";
import {GeocodeApiInstance} from "../../shared/utility/getCoordinatesFromAddress";
import {ROUTES} from "../../../submodules/naoo-web-components/Shared/constants";
import layoutClasses from "../Layout.module.scss";
import BusinessSidebar from "../Sidebar/BusinessSidebar";
import Topbar from "../Topbar/Topbar";
import DeleteBusinessModal from "./DeleteBusinessModal/DeleteBusinessModal";
import NameCategoryAboutAndLogo from "../../../submodules/naoo-web-components/Business/NameCategoryAboutAndLogo";
import Contacts from "../../../submodules/naoo-web-components/Business/Contacts";
import CoverAndGallery from "../../../submodules/naoo-web-components/Business/CoverAndGallery";
import Location from "../../../submodules/naoo-web-components/Business/Location";
import Schedule from "../../../submodules/naoo-web-components/Business/Schedule";
import {withAnalytics} from "../../shared/utility/withAnalytics";
import {AnalyticsContext} from "../../../submodules/naoo-web-components/Shared/context/analyticsContext";
import {FullscreenPreloader} from "../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import {BackButton, Button, TextButton} from "../../../submodules/naoo-web-components/Components/Button/Buttons";

const businessScheduleEvents =  {
    'custom_schedule_hours_day_btn_clicked' : withAnalytics('dashboards.business.business_profile.hours_day_btn_clicked'),
    'hours_add_range_btn_clicked' : withAnalytics('dashboards.business.business_profile.hours_add_range_btn_clicked'),
    'hours_remove_range_btn_clicked' : withAnalytics('dashboards.business.business_profile.hours_remove_range_btn_clicked'),
    'hours_from_field_clicked' : withAnalytics('dashboards.business.business_profile.hours_from_field_clicked'),
    'hours_from_dropdown_item_clicked' : withAnalytics('dashboards.business.business_profile.hours_from_dropdown_item_clicked'),
    'hours_to_field_clicked' : withAnalytics('dashboards.business.business_profile.hours_to_field_clicked'),
    'hours_to_dropdown_item_clicked' : withAnalytics('dashboards.business.business_profile.hours_to_dropdown_item_clicked'),
}

const EditBusiness = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const business = useSelector(state => selectBusiness(state))
    const categories = useSelector((state) => selectCategories(state))
    const businessId = useSelector(state => selectCurrentBusinessId(state))
    const [isUpdating, setIsUpdating] = useState(false)
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false)
    const [isOnline, setIsOnline] = useState(!business.address)
    const [logoName, setLogoName] = useState('')
    const [coverName, setCoverName] = useState('')
    const [isLoadingGallery, setIsLoadingGallery] = useState(false)

    const errors = {
        name: business?.name?.length === 0,
        website: !isValidWebsite(business?.website),
        phone: !isValidPhoneNumber(business?.phone) && business?.phone?.length !== 0,
        email: !isValidEmail(business?.email) && business?.email?.length !== 0,
        logo: !business?.logo,
        cover: !business?.cover,
        address: business?.address === '' && !isOnline,
        categories: business?.businessCategories?.length === 0
    }

    useEffect(() => {
        if (businessId) {
            dispatch(fetchCategories()).then(() => {
                dispatch(fetchBusiness(businessId))
            })
        }
    }, [dispatch, businessId])

    const logo = business?.logo?.url || business.logo
    const cover = business?.cover?.url || business.cover

    const formSubmit = withAnalytics(
        'dashboards.business.business_profile.save_btn_clicked',
        (event) => {
            console.log(123)
            event.preventDefault()
            setIsUpdating(true)
            const temp = {
                id: business.id,
                name: business.name,
                businessCategories: business.businessCategories,
                description: business.description,
                phone: business.phone,
                website: business.website,
                email: business.email,
                logo: business.logo,
                cover: business.cover,
                hours: business.customHours,
                address: business.address,
            }
            if (isOnline) {
                temp.coordinates = null
                temp.address = null
            } else {
                temp.coordinates = [business.coordinates[1], business.coordinates[0]]
            }
            dispatch(updateBusiness(temp)).then(() => {
                dispatch(fetchManagedBusinesses())
                setIsUpdating(false)
            })
        })

    const handleNameChange = (event) => {
        dispatch(setName(event.target.value))
        dispatch(setCurrentBusinessName(event.target.value))
    }

    const handleAboutChange = (event) => {
        dispatch(setDescription(event.target.value))
    }

    const handleCategoryChange = withAnalytics(
        'dashboards.business.business_profile.category_dropdown_item_clicked',
        (item) => {
            item.isSelected ? dispatch(removeCategory(item)) : dispatch(addCategory(item))
        })

    const handleLogoOnDrop = (acceptedFiles) => {
        setLogoName(acceptedFiles[0].name)
        const imgUrl = URL.createObjectURL(acceptedFiles[0])
        dispatch(setLogo(imgUrl))
        dispatch(setCurrentBusinessLogo(imgUrl))
        const img = new Image()
        img.src = imgUrl
        img.onload = function () {
            dispatch(setLogoDimensions([this.width, this.height]))
        }
    }

    const handleRemoveLogo = withAnalytics(
        'dashboards.business.business_profile.remove_profile_image_btn_clicked',
        () => {
            dispatch(setLogo(''))
        })

    const handleWebsiteChange = (event) => {
        dispatch(setWebsite(event.target.value))
    }

    const handleEmailChange = (event) => {
        dispatch(setEmail(event.target.value))
    }

    const handlePhoneChange = (event) => {
        dispatch(setPhone(event.target.value))
    }

    const handleGalleryChanges = (items) => {
        setIsLoadingGallery(true)
        dispatch(addProfileMediaThunk({
            businessId: business.id,
            urlList: items,
            mediaIds:business.profileMedia?.map(item=>item.id)
        }))
            .then(() => {
                setIsLoadingGallery(false)
            })
    }

    const deleteGalleryItem = withAnalytics(
        'dashboards.business.business_profile.gallery_remove_image_btn_clicked',
        (key) => {
            dispatch(deleteProfileMediaThunk({businessId: business.id, mediaId: key}))
        })

    const handleCoverOnDrop = (acceptedFiles) => {
        const imgUrl = URL.createObjectURL(acceptedFiles[0])
        setCoverName(acceptedFiles[0].name)
        dispatch(setCover(imgUrl))
        const img = new Image()
        img.src = imgUrl
        img.onload = function () {
            dispatch(setLogoDimensions([this.width, this.height]))
        }
    }

    const handleRemoveCover = withAnalytics(
        'dashboards.business.business_profile.remove_cover_image_btn_clicked',
        () => {
            dispatch(setCover(''))
        })


    const handleClickIntoMap = (address, lat, lng) => {
        dispatch(setAddress(address.formatted_address))
        dispatch(setCurrentBusinessAddress(address.formatted_address))
        dispatch(setCoordinates([lat, lng]))
    }

    const handleAddressChange = async (event) => {
        dispatch(setAddress(event.target.value))
        dispatch(setCurrentBusinessAddress(event.target.value))
        const coordinates = await GeocodeApiInstance.getCoordinatesFromAddress(event.target.value)
        dispatch(setCoordinates(coordinates))
    }

    const handleCheckBoxClick = () => {
        setIsOnline(!isOnline)
    }

    const handleDeleteButtonClick = withAnalytics(
        'dashboards.business.business_profile.delete_business_and_all_data_btn_clicked',
        () => {
            setIsShowDeleteModal(true)
        })

    const handleGalleryDropzoneClick = withAnalytics(
        'dashboards.business.business_profile.gallery_dropzone_clicked'
    )

    const handleGalleryOnDrop = withAnalytics(
        'dashboards.business.business_profile.gallery_dropzone_drag_n_drop',
    )

    const handleCategoryFieldClick = withAnalytics(
        'dashboards.business.business_profile.category_field_clicked'
    )

    return (
        <>
            {
                isShowDeleteModal &&
                <DeleteBusinessModal
                    business={business}
                    setIsShowModal={setIsShowDeleteModal}
                />
            }
            <div className={layoutClasses.Container}>
                <BusinessSidebar>
                    <div style={{height: 50}}/>
                </BusinessSidebar>
                <div className={layoutClasses.Main}>
                    <Topbar/>
                    <div className={classes.BusinessContainer}>
                        {
                            isUpdating && <FullscreenPreloader/>
                        }
                        <div>
                            <BackButton onClick={() => navigate(ROUTES.MAIN, {replace: true})}>Back to Dashboard </BackButton>
                        </div>
                        <p className={classes.Title}>Business profile</p>
                        <div className={classes.BusinessAndPreview}>
                            <div className={classes.Business}>
                                <form onSubmit={formSubmit}>
                                    <NameCategoryAboutAndLogo
                                        business={business}
                                        errors={errors}
                                        logo={logo}
                                        categories={categories}
                                        logoName={logoName}
                                        handleAboutChange={handleAboutChange}
                                        handleCategoryChange={handleCategoryChange}
                                        handleLogoOnDrop={handleLogoOnDrop}
                                        handleNameChange={handleNameChange}
                                        handleRemoveLogo={handleRemoveLogo}
                                        handleCategoryFieldClick={handleCategoryFieldClick}
                                    />
                                    <Contacts
                                        business={business}
                                        errors={errors}
                                        handleEmailChange={handleEmailChange}
                                        handlePhoneChange={handlePhoneChange}
                                        handleWebsiteChange={handleWebsiteChange}
                                    />
                                    <CoverAndGallery
                                        business={business}
                                        errors={errors}
                                        cover={cover}
                                        coverName={coverName}
                                        handleCoverOnDrop={handleCoverOnDrop}
                                        handleGalleryChanges={handleGalleryChanges}
                                        handleRemoveCover={handleRemoveCover}
                                        deleteGalleryItem={deleteGalleryItem}
                                        galleryIsLoading={isLoadingGallery}
                                        businessId={businessId}
                                        handleGalleryDropzoneClick={handleGalleryDropzoneClick}
                                        handleGalleryOnDrop={handleGalleryOnDrop}
                                    />
                                    <Location
                                        business={business}
                                        errors={errors}
                                        handleAddressChange={handleAddressChange}
                                        handleClickIntoMap={handleClickIntoMap}
                                        isOnline={isOnline}
                                        handleCheckBoxClick={handleCheckBoxClick}
                                    />
                                    <AnalyticsContext.Provider value={businessScheduleEvents}>
                                        <Schedule
                                            business={business}
                                            errors={errors}
                                            setHours={setHours}
                                        />
                                    </AnalyticsContext.Provider>
                                </form>
                            </div>
                            <div className={classes.Preview}>
                                <BusinessPreview name={business.name} logo={business.logo} cover={business.cover}/>
                            </div>
                            <div className={classes.ButtonsContainer}>
                                <TextButton type={'button'} onClick={handleDeleteButtonClick}>Delete business and all data</TextButton>
                                <Button
                                    disabled={
                                        errors.name || errors.email || errors.phone ||
                                        errors.website || errors.categories || errors.logo ||
                                        errors.cover || errors.address
                                    }
                                    type={'submit'}
                                    onClick={formSubmit}
                                >Save changes</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditBusiness;
