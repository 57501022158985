import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createMediaPresignedUrl, getMedia, mainCreateOffer } from '../../api/api'
import { OFFER_STATUS } from '../../app/shared/constants'
import { sendImage } from '../../submodules/naoo-web-components/Shared/utility/sendImage'

export const createOffer = createAsyncThunk(
	'newOffer/createOffer',
	async ({
		mediaURI,
		businessId,
		title,
		text,
		coordinates,
		price,
		discount,
		currency,
		decimals,
		startTime,
		endTime,
		tags,
		points,
			   claimsLimit,
		claimsPerUserLimit,
			   canBeClaimedAtLocation,
		hours,
		isActive,
	}) => {
		console.debug(`[createOfferSlice.js] createOffer`)
		// const mediaId = await sendImage(mediaURI, createMediaPresignedUrl, getMedia)
		const mediaId = (await sendImage(mediaURI, createMediaPresignedUrl, getMedia)).mediaId
		// Create offer record using the backend api

		if (!points) {
			points = (price * 100) / 10 ** decimals
		}

		const createOfferResp = await mainCreateOffer(
			businessId,
			title,
			text,
			mediaId,
			coordinates?.[1],
			coordinates?.[0],
			price * 100,
			discount * 100,
			currency,
			decimals,
			startTime,
			endTime,
			tags,
			points,
			claimsLimit,
			claimsPerUserLimit,
			canBeClaimedAtLocation,
			hours,
			isActive,
			!!canBeClaimedAtLocation ? coordinates : null
		)
		// check http code
		if (createOfferResp.status < 200 || createOfferResp.status >= 300) {
			console.debug('could not create offer, http status: ', createOfferResp.status)
		}
		return createOfferResp.data
	}
)

const initialState = {
	newOffer: { decimals: 2 },
	pendingMedia: null,
	status: 'idle',
	error: null,
}

const newOfferSlice = createSlice({
	name: 'newOffer',
	initialState,
	reducers: {
		discardedNewOffer(state, action) {
			console.debug('[newOfferSlice.js] discardedNewOffer action')
			state.pendingMedia = null
			state.newOffer = { decimals: 2 }
			state.status = 'idle'
		},
		updatedPendingMedia(state, action) {
			console.debug('[newOfferSlice.js] updatedPendingImageMetadata action:')
			console.debug(action)
			state.pendingMedia = action.payload.media
		},
		updatedNewOfferData(state, action) {
			console.debug('[newOfferSlice.js] updatedNewOfferData action: ')
			console.debug(action)
			if (action.payload.businessId !== undefined) {
				state.newOffer.businessId = action.payload.businessId
			}
			if (action.payload.title !== undefined) {
				state.newOffer.title = action.payload.title
			}
			if (action.payload.text !== undefined) {
				state.newOffer.text = action.payload.text
			}
			if (action.payload.coordinates !== undefined) {
				state.newOffer.coordinates = action.payload.coordinates
			}
			if (action.payload.media !== undefined) {
				state.newOffer.media = action.payload.media
			}
			if (action.payload.price !== undefined) {
				state.newOffer.price = action.payload.price
			}
			if (action.payload.discountedPrice !== undefined) {
				state.newOffer.discountedPrice = action.payload.discountedPrice
			}
			if (action.payload.discount !== undefined) {
				state.newOffer.discount = action.payload.discount
			}
			if (action.payload.currency !== undefined) {
				state.newOffer.currency = action.payload.currency
			}
			if (action.payload.decimals !== undefined) {
				state.newOffer.decimals = action.payload.decimals
			}
			if (action.payload.startTime !== undefined) {
				state.newOffer.startTime = action.payload.startTime
			}
			if (action.payload.endTime !== undefined) {
				state.newOffer.endTime = action.payload.endTime
			}
			if (action.payload.tags !== undefined) {
				state.newOffer.tags = action.payload.tags
			}
			if (action.payload.points !== undefined) {
				state.newOffer.points = action.payload.points
			}
			if (action.payload.claimsLimit !== undefined) {
				state.newOffer.claimsLimit = action.payload.claimsLimit
			}
			if (action.payload.claimsPerUserLimit !== undefined) {
				state.newOffer.claimsPerUserLimit = action.payload.claimsPerUserLimit
			}
			if (action.payload.canBeClaimedAtLocation !== undefined) {
				state.newOffer.canBeClaimedAtLocation = action.payload.canBeClaimedAtLocation
			}
			if (action.payload.address !== undefined) {
				state.newOffer.address = action.payload.address
			}
			if (action.payload.hours !== undefined) {
				state.newOffer.hours = action.payload.hours
			}
			if (action.payload.customHours !== undefined) {
				state.newOffer.customHours = action.payload.customHours
			}
			if (action.payload.isActive !== undefined) {
				state.newOffer.isActive = action.payload.isActive
			}
			state.status = OFFER_STATUS.CHANGED
		},
	},
	extraReducers: (builder)=>{
    builder.addCase(createOffer.pending,  (state, action) => {
			state.newOffer.status = 'loading'
			state.newOffer.error = null
		},)
    builder.addCase(createOffer.fulfilled, (state, action) => {
			state.newOffer.status = 'succeeded'
			state.newOffer.error = null
		},)
    builder.addCase(createOffer.rejected, (state, action) => {
			state.newOffer.status = 'failed'
			state.newOffer.error = action.error.message
		},)
  }
})

export const { updatedNewOfferData, updatedPendingMedia, discardedNewOffer } = newOfferSlice.actions

export default newOfferSlice.reducer

// === Selectors ===
export const selectNewOffer = (state) => {
	console.debug(state)
	return state.newOffer.newOffer
}

export const selectNewOfferStatus = (state) => state.newOffer.status

export const selectNewOfferError = (state) => state.newOffer.error

export const selectPendingMedia = (state) => state.newOffer.pendingMedia
