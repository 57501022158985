import React, {Fragment, useEffect, useState} from 'react';
import BusinessSidebar from "../Sidebar/BusinessSidebar";
import Topbar from "../Topbar/Topbar";
import PageTitle from "../../components/PageTitle/PageTitle";
import layoutClasses from "../Layout.module.scss";
import classes from './VoucherSamples.module.scss'
import Margin from "../../../submodules/naoo-web-components/Components/Margin/Margin";
import {useObserver} from "../../../submodules/naoo-web-components/Shared/hooks/useObserver";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchVoucherSamples,
    fetchVoucherSamplesByNextToken,
    selectFetching,
    selectNextToken,
    selectVoucherSamples,
    setActiveSample,
    setFetching,
    setStatus,
    updateVoucherSampleThunk
} from "../../../store/reducers/vouchersReducer";
import {Outlet, useLocation, useMatch, useNavigate} from "react-router-dom";
import MoreDropdown from "../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import MoreItem from "../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";
import {selectCurrentBusinessId} from "../../../store/reducers/managedBusinessesSlice";
import SampleVoucher from "./SampleVoucher/SampleVoucher";
import {returnObservableItem} from "../../../submodules/naoo-web-components/Shared/utility/utils";
import {Preloader} from "../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import spinnerClasses from "../../Spinner.module.scss";
import {Button} from "../../../submodules/naoo-web-components/Components/Button/Buttons";

const VoucherSamples = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [element, setElement] = useState(null)

    const LIMIT = 10

    const businessId = useSelector(selectCurrentBusinessId)
    const samples = useSelector(selectVoucherSamples)
    const nextToken = useSelector(selectNextToken)
    const fetching = useSelector(selectFetching)
    // const LIMIT = 10

    useEffect(() => {
        dispatch(fetchVoucherSamples({
            businessId
        }))
    }, [businessId, dispatch])

    useObserver(
        element,
        fetching, setFetching,
        nextToken, fetchVoucherSamplesByNextToken
    )

    const handleNewVoucherButtonClick = () => {
        dispatch(setStatus(null))
        dispatch(setActiveSample(null))
        navigate('/vouchers/options/create')
    }

    const handleOptionClick = (sample) => () => {
        dispatch(setActiveSample(sample))
        navigate(`/vouchers/options/${sample.id}`)
    }

    const MoreSampleDropdown = (sample) => (
        <MoreDropdown vertical bottomPosition width={'auto'}>
            <MoreItem
                text={sample?.isActive ? 'Deactivate' : 'Activate'}
                onClick={() => {
                    dispatch(updateVoucherSampleThunk({
                        sample: {
                            isActive: !sample?.isActive
                        },
                        id: sample?.id
                    }))
                }}
            />
            <MoreItem
                text={'Edit'}
                onClick={() => {
                    dispatch(setActiveSample(sample))
                    dispatch(setStatus(null))
                    navigate(`/vouchers/options/${sample?.id}/update`)
                }}
            />
        </MoreDropdown>
    )

    const match = useMatch('/vouchers')
    const matchImports = useMatch('/vouchers/options/:id/import')

    return (<>
            <div className={layoutClasses.Container}>
                <BusinessSidebar>
                    {
                        location.pathname !== '/vouchers'
                            ? <div style={{height: 50}}/>
                            : <Button
                                onClick={handleNewVoucherButtonClick}
                                disabled={location.pathname !== '/vouchers'}
                            >New Voucher</Button>
                    }
                </BusinessSidebar>
                <div className={layoutClasses.Main}>
                    <Topbar/>
                    <Outlet/>
                    {
                        (match || matchImports) &&
                        <div className={classes.Container}>
                            <PageTitle title={'Voucher samples'}/>
                            <Margin bottom={24}/>
                            <div className={classes.OptionsContainer}>
                                {
                                    samples?.map((item, index, array) =>
                                        <Fragment key={item.id}>
                                            <SampleVoucher
                                                key={item.id}
                                                sampleVoucher={item}
                                                onClick={handleOptionClick(item)}
                                                MoreDropdown={MoreSampleDropdown(item)}
                                            />
                                            {
                                                returnObservableItem(array.length, LIMIT, index, setElement)
                                            }
                                        </Fragment>
                                    )
                                }
                                {
                                    fetching && <Preloader className={spinnerClasses.paginationSpinnerSize}/>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default VoucherSamples;