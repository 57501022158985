import { createContext } from 'react'

export const RemoteConfigContext = createContext()

export const MODULE_OFFERS_ENABLED = 'offersEnabled'
export const MODULE_POSTS_ENABLED = 'postsEnabled'
export const MODULE_FOLLOWERS_ENABLED = 'followersEnabled'
export const MODULE_CHAT_ENABLED = 'chatEnabled'
export const MODULE_VOUCHERS_ENABLED = 'vouchersEnabled'
export const MODULE_BUSINESS_PROFILE_ENABLED = 'businessProfileEnabled'
export const POSTS_IMPORT_ENABLED = 'postsImportEnabled'
