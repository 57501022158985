import {Navigate, Outlet, useLocation} from 'react-router-dom';
import ApiClient from "../submodules/naoo-web-components/Api/client";
import {ROUTES} from "../submodules/naoo-web-components/Shared/constants";

export const PrivateWrapper = () => {
    const apiClient = new ApiClient()
    const location = useLocation()
    const isSignedIn = apiClient.getIsSignedIn();

    return isSignedIn
        ? location.pathname === '/' ? <Navigate to={ROUTES.ADMIN_DASHBOARD.POSTS} replace /> : <Outlet />
        : <Navigate to={ROUTES.AUTH.SIGN_UP} replace />;
}