import React, {useEffect, useState} from 'react';
import classes from './AddVouchers.module.scss'
import PageTitle from "../../../components/PageTitle/PageTitle";
import {useNavigate} from "react-router-dom";
import Margin from "../../../../submodules/naoo-web-components/Components/Margin/Margin";
import TableWrapper from "../../../components/Table/TableWrapper/TableWrapper";
import TableHeader from "../../../components/Table/TableHeader/TableHeader";
import {BackButton, Button, PlusButton} from "../../../../submodules/naoo-web-components/Components/Button/Buttons";
import VoucherItem from "./VoucherItem/VoucherItem";
import TableItem from "../../../components/Table/TableItem/TableItem";
import {useDispatch, useSelector} from "react-redux";
import {
    addVouchersThunk,
    selectActiveSample,
    selectStatus,
    setStatus
} from "../../../../store/reducers/vouchersReducer";
import {FullscreenPreloader} from "../../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import spinnerClasses from "../../../../submodules/naoo-web-components/Shared/Spinner.module.scss";

const AddVouchers = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const sample = useSelector(selectActiveSample)
    const status = useSelector(selectStatus)

    const defaultNewVoucher = {
        sampleId: sample?.id,
        editMode: true
    }

    const [vouchers, setVouchers] = useState([defaultNewVoucher])

    const isAddVoucherDisabled = vouchers.some((voucher) => voucher.editMode)
    const isAddVouchersDisabled = vouchers
        .filter(voucher => voucher.media).length === 0


    const handleBackButtonClick = () => {
        navigate(-1)
    }

    const handleAddNewVoucherClick = () => {
        setVouchers(prev => [...prev, defaultNewVoucher])

    }

    const handleVoucherChange = (changedVoucherIndex) => (changedVoucher) => {
        const changedVouchers = vouchers.map((voucher, index) => {
            return changedVoucherIndex === index
                ? changedVoucher
                : voucher
        })
        setVouchers(changedVouchers)
    }

    const handleDeleteClick = (deletedVoucherIndex) => () => {
        const changedVouchers = vouchers.filter((_,index)=>deletedVoucherIndex !== index)
        setVouchers(changedVouchers)
    }

    const handleAddVouchersClick = async () => {
        dispatch(addVouchersThunk(vouchers.filter(voucher => !voucher.editMode)))
    }

    useEffect(() => {
        if (status === 'successfully') {
            dispatch(setStatus(null))
            navigate(-1)
        }
    }, [status, dispatch, navigate])

    return (
        <>
            {
                status === 'loading' &&
                <FullscreenPreloader
                    overlayClassName={spinnerClasses.lightBlurPreloader}
                    spinnerClassName={spinnerClasses.spinnerSize}
                />
            }
            <div className={classes.Container}>
                <BackButton onClick={handleBackButtonClick}>
                    Discard vouchers
                </BackButton>
                <Margin top={12}/>
                <PageTitle title={'Adding new vouchers'}/>
                <TableWrapper>
                    <TableHeader className={classes.Header}>
                        <div className={classes.Media}>
                            <span>Media</span>
                        </div>
                        <div className={classes.CrmId}>
                            <span>ID in CRM</span>
                        </div>
                    </TableHeader>
                    {
                        vouchers?.map((voucher, index) => {
                            return <TableItem key={index} className={classes.Item}>
                                <VoucherItem
                                    voucher={voucher}
                                    editMode={voucher.editMode}
                                    onChange={handleVoucherChange(index)}
                                    onDelete={handleDeleteClick(index)}
                                />
                            </TableItem>
                        })
                    }
                    <PlusButton
                        className={classes.AddButton}
                        disabled={isAddVoucherDisabled}
                        onClick={handleAddNewVoucherClick}
                    >
                        Add new vouchers
                    </PlusButton>
                </TableWrapper>
                <div className={classes.BottomButtons}>
                    <BackButton
                        onClick={handleBackButtonClick}
                    >
                        Back to {sample?.title}
                    </BackButton>
                    <Button
                        disabled={isAddVouchersDisabled}
                        onClick={handleAddVouchersClick}
                    >
                        Add vouchers
                    </Button>
                </div>
            </div>
        </>
    );
};

export default AddVouchers;