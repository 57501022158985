import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom'
import classes from "../CreateBusines.module.scss";
import {useDispatch} from "react-redux";
import {
    addProfileMediaThunk,
    clearBusiness,
    createBusiness,
    setAddress,
    setCoordinates
} from "../../../../store/reducers/businessSlice";
import {updatedDashboardIsObsolete} from "../../../../store/reducers/dashboardSlice";
import {setIsShowCreateBusiness} from "../../../../store/reducers/managedBusinessesSlice";
import {CREATE_BUSINESS_STEP_NAMES} from "../CreateBusiness";
import {ROUTES} from "../../../../submodules/naoo-web-components/Shared/constants";
import CheckboxSelectedIcon from "../../../../submodules/naoo-web-components/Shared/UI/assets/CheckBoxSelected.svg";
import CheckboxNotSelectedIcon
    from "../../../../submodules/naoo-web-components/Shared/UI/assets/CheckBoxNotSelected.svg";
import {withAnalytics} from "../../../shared/utility/withAnalytics";
import Map from "../../../../submodules/naoo-web-components/Components/Map/Map";
import Input from "../../../../submodules/naoo-web-components/Components/Input/Input";
import {BackButton, Button} from "../../../../submodules/naoo-web-components/Components/Button/Buttons";
import {FullscreenPreloader} from "../../../../submodules/naoo-web-components/Components/Preloader/Preloader";


const Step4 = ({business, setStep, closeBusiness}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [isOnline, setIsOnline] = useState(false)

    const handleAddressChange = (event) => {
        dispatch(setAddress(event.target.value))
    }

    const handleClickIntoMap = (address, lat, lng) => {
        dispatch(setAddress(address.formatted_address))
        dispatch(setCoordinates([lat, lng]))
    }

    const handleCheckBoxClick = () => {
        setIsOnline(!isOnline)
    }

    const handleBackButtonClick = () => {
        setStep(CREATE_BUSINESS_STEP_NAMES.ABOUT)
    }

    const handleCreateBusiness = withAnalytics(
        'dashboards.business.create_business.location_page.finish_btn_clicked',
        (event) => {
            setIsLoading(true)
            event.preventDefault()
            const temp = JSON.parse(JSON.stringify(business))
            temp.businessCategories.forEach((item) => {
                delete item['isSelected']
            })
            if(isOnline) {
                delete temp.coordinates
                delete temp.address
            }
            else{
                temp.coordinates = [business.coordinates[1],business.coordinates[0]]
            }

            dispatch(createBusiness(temp)).then((action) => {
                dispatch(addProfileMediaThunk({businessId: action.payload.id, urlList: temp.profileMedia})).then(() => {
                    dispatch(clearBusiness())
                    dispatch(updatedDashboardIsObsolete({isObsolete: true}))
                    setIsLoading(false)
                    navigate(ROUTES.MAIN, {replace: true})
                    dispatch(setIsShowCreateBusiness(false))
                })
            })
        }
    )


    return (
        <>
            {isLoading && <FullscreenPreloader/>}
            <p className={classes.Subtitle}>Let customers know where your business provides deliveries or services.</p>
            <div className={classes.BusinessForm}>
                <div className={classes.CheckboxContainer} onClick={handleCheckBoxClick}>
                    <img src={isOnline ? CheckboxSelectedIcon : CheckboxNotSelectedIcon} alt=""/>
                    <span className={classes.CheckboxLabel}>Online only</span>
                </div>

                {
                    !isOnline &&
                    <>
                        <Input
                            containerClassName={classes.InputContainer}
                            label={'Address'}
                            name={'address'}
                            type="text"
                            placeholder={'Enter your address'}
                            value={business.address}
                            onChange={handleAddressChange}
                        />
                        <div className={classes.MapContainer}>
                            <Map
                                defaultCenterLng={8.55}
                                defaultCenterLat={47.3666700}
                                lat={business.coordinates[0]}
                                lng={business.coordinates[1]}
                                defaultZoom={15}
                                onClick={handleClickIntoMap}
                            />
                        </div>
                    </>
                }

                <div className={classes.ButtonsContainer}>
                    <BackButton
                        className={classes.BackButton}
                        onClick={handleBackButtonClick}
                    >
                        Back to About section
                    </BackButton>
                    <Button
                        className={classes.Button}
                        disabled={(business?.address?.length === 0 && !isOnline)}
                        onClick={handleCreateBusiness}
                    >
                        Finish creating business
                    </Button>
                </div>
            </div>
        </>


    );
};

export default Step4;
