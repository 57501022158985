import React from 'react';
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import {ErrorsBlock} from "../SignUp";
import {Button} from "../../../Components/Button/Buttons";
import Input from "../../../Components/Input/Input";
import {isValidPhoneNumber} from "../../../Shared/utility/phoneNumberValidation";
import {isValidEmail} from "../../../Shared/utility/emailValidation";
import classes from '../../Auth.module.scss'
import signUpClasses from '../SingUp.module.scss'
import CheckBox from "../../../Components/CheckBox/CheckBox";

const Step2 = ({setStep, setData}) => {
    const {register, handleSubmit, watch, formState: {errors}} = useForm({
        mode: "onChange"
    });

    const onSubmit = (formData) => {
        setData((prevState) => {
            return {
                ...prevState,
                ...formData
            }
        })
        setStep(3)
    }

    const watchTabs = watch('tab', 'phone')
    const watchPolicy = !watch('policy')

    return (
        <>
            <div className={classes.AuthForm}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <span className={classes.FormTitle}>Sign up with Phone <br/>or Email</span>
                    <div className={signUpClasses.TabContainer}>
                        <label className={signUpClasses.Tab}>
                            <input {...register('tab')} type="radio" value={'phone'} defaultChecked/>
                            <span>Phone</span>
                        </label>
                        <label className={signUpClasses.Tab}>
                            <input {...register('tab')} type="radio" value={'email'}/>
                            <span>Email</span>
                        </label>
                    </div>
                    {
                        watchTabs === 'phone' &&
                        <Input
                            register={
                                register('phone',{
                                    required: 'Phone is a required field',
                                    validate: {
                                        isPhoneValid:
                                            value => !isValidPhoneNumber(value) ? "Please enter a valid phone number" : undefined,
                                    }
                                })}
                            containerClassName={classes.Input}
                            type={'tel'}
                            label={'Phone number'}
                            placeholder={'Phone'}
                            error={errors?.phone}
                            onChange={(event) => {
                                if (event.target.value.match(/[^0-9\\+]/g)) {
                                    event.target.value = event.target.value.replace(/[^0-9\\+]/g, "");
                                }
                            }}
                        />
                    }
                    {
                        watchTabs === 'email' &&
                        <Input
                            register={
                                register('email',{
                                    required: 'Email is a required field',
                                    validate: {
                                        isEmailValid: value => !isValidEmail(value) ? "Please enter a valid email" : null,
                                    },
                                })}
                            containerClassName={classes.Input}
                            type={'email'}
                            label={'Email'}
                            placeholder={'Email'}
                            error={errors?.email}
                        />
                    }
                    <div className={signUpClasses.CheckBoxContainer}>
                        <CheckBox register={register('policy',{
                            required: 'Please accept the Terms and Conditions and Privacy Policy to continue'
                        })} id={'policyCheckbox'}/>
                        <label className={signUpClasses.Label} htmlFor="policyCheckbox">
                            I have read and agree to the <Link to={'/signUp'}>Terms and Conditions</Link> and <Link
                            to={'/signUp'}>Privacy Policy</Link>
                        </label>
                    </div>
                    <Button className={signUpClasses.Button}
                            disabled={watchPolicy || errors?.policy || errors?.phone || errors?.email}>Continue</Button>
                </form>
                <span className={signUpClasses.Text}>Already have an account? <Link to={'/auth'}>Log in</Link></span>
            </div>
            <ErrorsBlock formErrors={errors}/>
        </>
    );
};

export default Step2;
