import {createRequest} from "../submodules/naoo-web-components/Api/createRequest";
import {
    generateUrlWithQueryParams
} from "../submodules/naoo-web-components/Api/generateUrlWithQueryParams/generateUrlWithQueryParams";

const getBusinesses = ({q, nextToken}) => {
    const url = generateUrlWithQueryParams('/businesses', {
        q,
        nextToken
    })
    return createRequest('main', 'get', url, null)
}

export { getBusinesses }