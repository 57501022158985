import React, {Fragment, useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from "react-router-dom";
import SubmitButton from './UI/SubmitButton'
import ApiClient from "../Api/client";
import {isValidPhoneNumber} from "../Shared/utility/phoneNumberValidation";
import {isValidEmail} from "../Shared/utility/emailValidation";
import {ROUTES} from "../Shared/constants";
import classes from './Auth.module.scss'
import {createMessage, MESSAGES_TYPES} from "../Components/Message/Message";
import {executeIfExist} from "../Shared/utility/utils";
import {useDispatch} from "react-redux";
import {addMessageThunk} from "../Shared/reducers/messagesReducer";
import Input from "../Components/Input/Input";
import {getSelf} from "../../../api/userApi";

const Auth = ({logo, setCredentials, isAdminDashboard}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const [state, setState] = useState({
        login: '',
        password: '',
        isValidEmail: false,
        isValidPhoneNumber: false,
        isValidPassword: false,
        loading: false,
    })

    const handleLoginInputChange = (event) => {
        const login = event.target.value
        let formattedLogin = '' + login
        // replace "0" with "+41" in phone number
        if (isValidPhoneNumber(formattedLogin) && formattedLogin[0] === '0') {
            formattedLogin = '+41' + formattedLogin.slice(1)
        }

        setState((prevState) => ({
            ...prevState,
            login: formattedLogin,
            isValidEmail: formattedLogin && isValidEmail(formattedLogin),
            isValidPhoneNumber: formattedLogin && isValidPhoneNumber(formattedLogin),
        }))
    }

    const handlePasswordInputChange = (event) => {
        const pwd = event.target.value
        setState({
            ...state,
            password: pwd,
            isValidPassword: pwd && pwd.length > 0,
        })
    }


    const handleSubmitForm = (event) => {
        event.preventDefault()
        attemptSignIn(state.login, state.password)
    }

    const attemptSignIn = (login, password) => {
        const api = new ApiClient()
        api.signIn(
            login,
            password,
            () => {
                setState({
                    ...state,
                    loading: true,
                })
            },
            async (result) => {
                if (isAdminDashboard) {
                    const res =   await getSelf()
                    if (res.data.naooTeamRole !== 1) {
                        dispatch(addMessageThunk({
                            message: createMessage({
                                message: 'Incorrect username or password',
                                type: MESSAGES_TYPES.ERROR
                            })
                        }))
                        api.signOut()
                        setState({
                            ...state,
                            loading: false,
                        })
                        return
                    }
                }

                navigate(ROUTES.MAIN, {replace: true})
                executeIfExist(setCredentials)
            },
            (err) => {
                dispatch(addMessageThunk({
                    message: createMessage({
                        message: 'Incorrect username or password',
                        type: MESSAGES_TYPES.ERROR
                    })
                }))
                setState({
                    ...state,
                    loading: false,
                })
            }
        )
    }

    useEffect(() => {
        const email = location?.state?.email
        if (email) {
            setState((prevState) => ({
                ...prevState,
                login: email
            }))
        }
    }, [location?.state?.email])

    return (
        <Fragment>
            <img src={logo} alt="" className={classes.NaooBusinessLogo}/>
            <form className={classes.AuthForm} onSubmit={handleSubmitForm}>
                <label className={classes.FormTitle}>
                    {
                        location?.state?.isRedirected
                            ? `This email is already in use. Please log in`
                            : `Please log in`
                    }
                </label>
                <Input
                    containerClassName={classes.Input}
                    label={'Email or phone'}
                    value={state.login}
                    placeholder={'Your e-mail or phone number'}
                    onChange={handleLoginInputChange}
                />
                <Input
                    label={'Password'}
                    type="password"
                    placeholder={'Your password'}
                    onChange={handlePasswordInputChange}
                />
                <SubmitButton
                    onClick={handleSubmitForm}
                    isLoading={state.loading}
                />
                <Link
                    className={classes.ForgotPasswordLink}
                    to={'/forgotPassword'}
                >
                    Forgot your password?
                </Link>
            </form>
        </Fragment>
    )
}

export default Auth
