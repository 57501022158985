import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import classes from './EditOffer.module.scss'
import OfferPreview from "../UI/OfferPreview";
import {selectCurrentBusiness} from "../../../../store/reducers/managedBusinessesSlice";
import {
    selectMutabletOffer,
    selectMedia,
    updateOffer,
    updatedPendingMedia, updateOfferData, selectStatus
} from "../../../../store/reducers/editOfferSlice";
import {updatedDashboardIsObsolete} from "../../../../store/reducers/dashboardSlice";
import {OFFER_STATUS} from "../../../shared/constants";
import OfferInfo from "../../../../submodules/naoo-web-components/Offer/OfferInfo";
import OfferSchedule from "../../../../submodules/naoo-web-components/Offer/OfferSchedule";
import OfferPricing from "../../../../submodules/naoo-web-components/Offer/OfferPricing";
import OfferSettings from "../../../../submodules/naoo-web-components/Offer/OfferSettings";
import {useNavigate} from "react-router-dom";
import DiscardOfferModal from "../UI/DiscardOfferModal";
import {withAnalytics} from "../../../shared/utility/withAnalytics";
import {AnalyticsContext} from "../../../../submodules/naoo-web-components/Shared/context/analyticsContext";
import {FullscreenPreloader} from "../../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import {BackButton, Button} from "../../../../submodules/naoo-web-components/Components/Button/Buttons";


const EditOffer = (props) => {
    const navigate = useNavigate()
    const [isUploading, setIsUploading] = useState(false)
    const [isShowModal, setIsSHowModal] = useState(false)

    const dispatch = useDispatch()
    const currentBusiness = useSelector((state) => selectCurrentBusiness(state))
    const offer = useSelector(state => selectMutabletOffer(state))
    const media = useSelector(state => selectMedia(state))
    const status = useSelector(state => selectStatus(state))

    const handleDiscardButtonClick = () => {
        if (status === OFFER_STATUS.CHANGED) {
            setIsSHowModal(true)
        } else {
            props.discardOfferClicked()
        }
    }

    const discardOfferButton = (
        <BackButton onClick={handleDiscardButtonClick}> Discard changes </BackButton>
    )

    const handleNextButtonClick = () => {
        setIsUploading(true)
        dispatch(
            updateOffer({
                mediaURI: media,
                offerId: offer.id,
                title: offer.title,
                text: offer.text,
                media: offer.media,
                price: offer.price,
                discount: offer.discount,
                decimals: offer.decimals,
                startTime: offer.startTime,
                endTime: offer.endTime,
                tags: offer.tags,
                claimsLimit: offer.claimsLimit,
                claimsPerUserLimit: offer.claimsPerUserLimit,
                canBeClaimedAtLocation: offer.canBeClaimedAtLocation,
                hours: offer?.customHours?.length !== 0 ? offer.customHours : offer.hours,
                isActive: offer.isActive,
                coordinates: [offer.coordinates[1], offer.coordinates[0]]
            })
        ).then((res) => {
                setIsUploading(false)
                dispatch(updatedDashboardIsObsolete({isObsolete: true}))
                navigate(-1)
            }
        ).catch((err) => {
            setIsUploading(false)
            console.error(err)
        })
    }

    const scheduleEventNames = {
        'timeframe_start_field_clicked': withAnalytics('dashboards.business.edit_offer.schedule_page.timeframe_start_field_clicked'),
        'timeframe_end_field_clicked': withAnalytics('dashboards.business.edit_offer.schedule_page.timeframe_end_field_clicked'),
        'no_schedule_btn_clicked': withAnalytics('dashboards.business.edit_offer.schedule_page.no_schedule_btn_clicked'),
        'preset_schedule_btn_clicked': withAnalytics('dashboards.business.edit_offer.schedule_page.preset_schedule_btn_clicked'),
        'preset_schedule_field_clicked': withAnalytics('dashboards.business.edit_offer.schedule_page.preset_schedule_field_clicked'),
        'preset_schedule_dropdown_item_clicked': withAnalytics('dashboards.business.edit_offer.schedule_page.preset_schedule_dropdown_item_clicked'),
        'custom_schedule_btn_clicked': withAnalytics('dashboards.business.edit_offer.schedule_page.custom_schedule_btn_clicked'),
        'custom_schedule_hours_day_btn_clicked': withAnalytics('dashboards.business.edit_offer.schedule_page.custom_schedule_hours_day_btn_clicked'),
        'hours_add_range_btn_clicked': withAnalytics('dashboards.business.edit_offer.schedule_page.custom_schedule_hours_add_range_btn_clicked'),
        'hours_remove_range_btn_clicked': withAnalytics('dashboards.business.edit_offer.schedule_page.custom_schedule_hours_remove_range_btn_clicked'),
        'hours_from_field_clicked': withAnalytics('dashboards.business.edit_offer.schedule_page.custom_schedule_hours_from_field_clicked'),
        'hours_from_dropdown_item_clicked': withAnalytics('dashboards.business.edit_offer.schedule_page.custom_schedule_hours_from_dropdown_item_clicked'),
        'hours_to_field_clicked': withAnalytics('dashboards.business.edit_offer.schedule_page.custom_schedule_hours_to_field_clicked'),
        'hours_to_dropdown_item_clicked': withAnalytics('dashboards.business.edit_offer.schedule_page.custom_schedule_hours_to_dropdown_item_clicked'),
    }

    const infoAnalyticsEvents = {
        'image_dropzone_clicked': withAnalytics('dashboards.business.edit_offer.info_page.image_dropzone_clicked'),
        'image_dropzone_drag_n_drop': withAnalytics('dashboards.business.edit_offer.info_page.image_dropzone_drag_n_drop'),
        'image_dropzone_delete_btn_clicked': withAnalytics('dashboards.business.edit_offer.info_page.image_dropzone_delete_btn_clicked'),
    }

    return (
        isUploading ?
            <FullscreenPreloader/>
            : <>
                <DiscardOfferModal
                    discardOfferClicked={props.discardOfferClicked}
                    isOpen={isShowModal}
                    onCancel={() => setIsSHowModal(false)}
                    offerTitle={offer?.title}
                />
                <div className={classes.EditOfferContainer}>
                    {discardOfferButton}
                    <p className={classes.Title}>Edit offer</p>
                    <div className={classes.ComponentAndPreview}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <AnalyticsContext.Provider value={infoAnalyticsEvents}>
                                <OfferInfo
                                    offer={offer}
                                    media={media}
                                    updateMedia={updatedPendingMedia}
                                    updateOffer={updateOfferData}
                                />
                            </AnalyticsContext.Provider>

                            <AnalyticsContext.Provider value={scheduleEventNames}>
                                <OfferSchedule
                                    offer={offer}
                                    updateOffer={updateOfferData}
                                />
                            </AnalyticsContext.Provider>

                            <OfferPricing
                                offer={offer}
                                updateOffer={updateOfferData}
                            />
                            <OfferSettings
                                offer={offer}
                                updateOffer={updateOfferData}
                            />
                        </div>
                        <OfferPreview
                            offer={offer}
                            businessName={currentBusiness ? currentBusiness.name : 'Business Name'}
                            businessLogoUrl={
                                currentBusiness && currentBusiness.logo && currentBusiness.logo.url
                                    ? currentBusiness.logo.url
                                    : null
                            }
                            offerTitle={
                                offer && offer.title ? offer.title : 'Offer Title'
                            }
                            offerText={
                                offer && offer.text
                                    ? offer.text
                                    : 'Describe your offer'
                            }
                            offerImage={media?.id ? media.url : media}
                            offerPrice={offer.price}
                            offerDiscount={
                                offer.price && offer.discountedPrice
                                    ? offer.price - offer.discountedPrice
                                    : null
                            }
                        />
                        <div className={classes.ButtonsContainer}>
                            {discardOfferButton}
                            <Button onClick={handleNextButtonClick}>Save changes</Button>
                        </div>
                    </div>
                </div>
            </>
    );
};

export default EditOffer;
