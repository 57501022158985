import React from 'react';
import EditMode from "./EditMode/EditMode";
import PresentationMode from "./PresentationMode/PresentationMode";

const VoucherItem = ({voucher, editMode, onChange, onDelete}) => {
    return (
        <>
            {
                editMode
                    ? <EditMode voucher={voucher} onChange={onChange}/>
                    : <PresentationMode voucher={voucher}  onChange={onChange} onDelete={onDelete}/>
            }
        </>
    );
};

export default VoucherItem;