import React, {useEffect, useRef, useState} from 'react';
import ModalWindow from "../../../../submodules/naoo-web-components/Components/ModalWindow/ModalWindow";
import {Link, useNavigate} from "react-router-dom";
import FileDropZone from "../../../../submodules/naoo-web-components/Components/FileDropZone/FileDropZone";
import {useDispatch, useSelector} from "react-redux";
import * as parser from "papaparse";
import classes from './ImportVouchersModal.module.scss'
import ChoosingVoucherItem, {MEDIA_TYPE} from "./ChoosingVoucherItem/ChoosingVoucherItem";
import {
    createVouchersThunk,
    fetchVouchers, fetchVouchersByNextToken,
    selectActiveSample,
    selectNextToken,
    selectVouchers, updateVoucherThunk
} from "../../../../store/reducers/vouchersReducer";
import {addMessageThunk} from "../../../../submodules/naoo-web-components/Shared/reducers/messagesReducer";
import {IMPORT_ERROR_TYPES} from "../../../shared/utility/importUtils/commonUtils";
import {
    validateVoucherStructure,
    voucherImportErrorMessages
} from "../../../shared/utility/importUtils/importVouchersUtils";
import {FullscreenPreloader} from "../../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import spinnerClasses from "../../../Spinner.module.scss";
import {sendImage} from "../../../../submodules/naoo-web-components/Shared/utility/sendImage";
import {createMediaPresignedUrl, getMedia} from "../../../../api/api";
import {createMessage, MESSAGES_TYPES} from "../../../../submodules/naoo-web-components/Components/Message/Message";

const ImportVouchersModal = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const currentSample = useSelector(selectActiveSample)
    const nextToken = useSelector(selectNextToken)
    const vouchers = useSelector(selectVouchers)

    const fetchVouchersRef = useRef(true)
    useEffect(() => {
        if (fetchVouchersRef.current && !nextToken) {
            dispatch(fetchVouchers({
                sampleId: currentSample?.id,
            }))
            fetchVouchersRef.current = false
        }
        if (nextToken) {
            dispatch(fetchVouchersByNextToken({
                nextToken
            }))
        }
    }, [currentSample, nextToken, dispatch])

    const [files, setFiles] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const [vouchersMediaToChoose, setVouchersMediaToChoose] = useState([])

    const hasMediaToChoose = !!vouchersMediaToChoose.length

    const handleFilesChange = (files) => {
        setFiles(files)
    }

    const handleCloseClick = () => {
        navigate(-1)
    }

    const showTableFormatError = () => {
        dispatch(addMessageThunk({
            message: voucherImportErrorMessages[IMPORT_ERROR_TYPES.INVALID_TABLE_FORMAT]
        }))
    }

    const processDataFromCsv = async (data) => {
        if (!data.length) {
            dispatch(addMessageThunk({
                message: voucherImportErrorMessages[IMPORT_ERROR_TYPES.FILE_EMPTY]
            }))
            return
        }

        if (!validateVoucherStructure(data[0])) {
            showTableFormatError()
            return
        }

        const patchResult = []
        const postResult = []

        for (const row of data) {
            const {id, mediaUrl, idInCrm} = row

            try {
                let mediaId = null

                if (mediaUrl) {
                    mediaId = (await sendImage(mediaUrl, createMediaPresignedUrl, getMedia)).id
                }

                if (id) {
                    const oldVoucher = vouchers?.find(voucher => voucher.id === id)
                    if (oldVoucher) {
                        patchResult.push({
                            naooId: id,
                            crmId: idInCrm,
                            oldMedia: oldVoucher?.media,
                            newMedia: {
                                id: mediaId,
                                url: mediaUrl
                            }
                        })
                    } else {
                        dispatch(addMessageThunk({
                            message: createMessage({
                                message: `Couldn't update voucher with ID "${id}" and image URL "${mediaUrl}": ID not found. To create a new voucher instead, remove the ID from the CSV file.`,
                                type: MESSAGES_TYPES.ERROR,
                            })
                        }))
                    }
                } else {
                    postResult.push({
                        sampleId: currentSample?.id,
                        mediaId: mediaId,
                        idInCrm
                    })
                }
            } catch (e) {
                console.log(e)
                showTableFormatError()
                return
            }
        }
        dispatch(createVouchersThunk({
            vouchers: postResult
        }))
            .unwrap()
            .then(() => {
                dispatch(addMessageThunk({
                    message: createMessage({
                        message: `Added ${postResult.length} vouchers`,
                        type: MESSAGES_TYPES.SUCCESS,
                    })
                }))
            })

        if (!patchResult.length) {
            navigate(-1)
        } else {
            setVouchersMediaToChoose(patchResult)
            dispatch(addMessageThunk({
                message: createMessage({
                    message: `Choose media version for ${patchResult?.length} more vouchers to save changes`,
                    type: MESSAGES_TYPES.ERROR,
                })
            }))
        }
    }

    const handleUploadCSVClick = () => {
        setIsLoading(true)
        parser.parse(files[0], {
            header: true,
            complete: async (results) => {
                await processDataFromCsv(results.data)
                setIsLoading(false)
            },
            error: (error) => {
                console.log(error)
                setIsLoading(false)
            }
        });
    }

    const handleConfirmMediaChoosing = () => {
        vouchersMediaToChoose?.forEach(item => {
            if (item?.currentChosenType === MEDIA_TYPE.NEW) {
                dispatch(updateVoucherThunk({
                    data: {
                        voucher: {
                            mediaId: item?.newMedia,
                            idInCrm: item?.idInCrm
                        }
                    },
                    id: item?.naooId
                }))
            }
        })
        navigate(-1)
    }

    const handleMediaClick = (index, type) => {
        const arrCopy = [...vouchersMediaToChoose]
        arrCopy[index].currentChosenType = type
        setVouchersMediaToChoose(arrCopy)
    }

    const isCheckedAllMedia = vouchersMediaToChoose.reduce((acc, item) => acc * !!item.currentChosenType, true)

    return (
        <>
            {
                isLoading &&
                <FullscreenPreloader
                    overlayClassName={spinnerClasses.lightBlurPreloader}
                    spinnerClassName={spinnerClasses.spinnerSize}
                />
            }
            <ModalWindow
                isOpen
                onClose={handleCloseClick}
                title={hasMediaToChoose ? `Choose media version for each of ${vouchersMediaToChoose.length} vouchers` : 'Import vouchers '}
                primaryButton={hasMediaToChoose ? 'Save changes' : 'Upload CSV'}
                disabledPrimary={hasMediaToChoose ? !isCheckedAllMedia : !files.length}
                handlePrimaryClick={hasMediaToChoose ? handleConfirmMediaChoosing : handleUploadCSVClick}
            >
                {
                    hasMediaToChoose
                        ? <div className={classes.ChoosingMediaContainer}>
                            {
                                vouchersMediaToChoose?.map((item, index) => (
                                    <ChoosingVoucherItem
                                        key={index}
                                        naooId={item.naooId}
                                        crmId={item.crmId}
                                        index={index}
                                        newMedia={item.newMedia}
                                        oldMedia={item.oldMedia}
                                        mediaType={item.currentChosenType}
                                        onMediaClick={handleMediaClick}
                                    />
                                ))
                            }
                        </div>
                        : <>
                            <ol className={classes.List}>
                                <li>
                                    Download the CSV <Link to="/data/vouchers.csv" target="_blank" download>template</Link>
                                </li>
                                <li>Add your vouchers data to it</li>
                                <li>Save and upload the CSV file</li>
                                <li>Vouchers from the file will be attached to your voucher sample</li>
                            </ol>
                            <div className={classes.FileContainer}>
                                <FileDropZone
                                    files={files}
                                    onChange={handleFilesChange}
                                    accept={'text/csv'}
                                    maxFiles={1}
                                />
                            </div>
                        </>
                }
            </ModalWindow>
        </>

    );
};

export default ImportVouchersModal;