import React from 'react';
import classes from './ChoosingVoucherItem.module.scss'

export const MEDIA_TYPE = {
    OLD: 'oldMedia',
    NEW: 'newMedia'
}

const ChoosingVoucherItem = ({index, naooId, crmId, oldMedia, newMedia, mediaType, onMediaClick}) => {
    return (
        <div className={classes.Container}>
            <div className={`${classes.Id} ${!mediaType ? classes.IsError : '' }`}>
                <span>{index}.</span>
                <span>Naoo ID: {naooId}</span>
                {
                    crmId && <span className={classes.CrmId}>CRM ID: {crmId}</span>
                }
            </div>
            <div className={classes.MediaLine}>
                <div
                    className={`${classes.Media} ${mediaType === MEDIA_TYPE.OLD ? classes.IsActive : ''}`}
                    onClick={()=>{onMediaClick(index, MEDIA_TYPE.OLD)}}
                >
                    <img className={classes.Img} src={oldMedia.url} alt=""/>
                    <span className={classes.Url}>{oldMedia.url}</span>
                </div>
                <div
                    className={`${classes.Media} ${mediaType === MEDIA_TYPE.NEW ? classes.IsActive : ''}`}
                    onClick={()=>{onMediaClick(index, MEDIA_TYPE.NEW)}}
                >
                    <img className={classes.Img} src={'/img.jpg'} alt=""/>
                    <span className={classes.Url}>{newMedia.url}</span>
                </div>
            </div>
        </div>
    );
};

export default ChoosingVoucherItem;