import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import classes from './CreateOffer.module.scss'
import StepsOverview from '../UI/OfferStepsOverview'
import {
    createOffer,
    selectNewOffer, selectNewOfferStatus,
    selectPendingMedia, updatedNewOfferData, updatedPendingMedia,
} from '../../../../store/reducers/newOfferSlice'
import {selectCurrentBusiness} from '../../../../store/reducers/managedBusinessesSlice'
import OfferPreview from "../UI/OfferPreview";
import {OFFER_STATUS} from "../../../shared/constants";
import OfferInfo from "../../../../submodules/naoo-web-components/Offer/OfferInfo";
import OfferSchedule from "../../../../submodules/naoo-web-components/Offer/OfferSchedule";
import OfferPricing from "../../../../submodules/naoo-web-components/Offer/OfferPricing";
import OfferSettings from "../../../../submodules/naoo-web-components/Offer/OfferSettings";
import {useNavigate} from "react-router-dom";
import DiscardOfferModal from "../UI/DiscardOfferModal";
import {withAnalytics} from "../../../shared/utility/withAnalytics";
import {AnalyticsContext} from "../../../../submodules/naoo-web-components/Shared/context/analyticsContext";
import {FullscreenPreloader} from "../../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import {BackButton, Button} from "../../../../submodules/naoo-web-components/Components/Button/Buttons";

const CreateOffer = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // step number
    const [currentStepNumber, setCurrentStepNumber] = useState(1)
    // block 'next' button when upload is in progress
    const [isUploading, setIsUploading] = useState(false)
    //for modal
    const [isShowModal, setIsSHowModal] = useState(false)
    const status = useSelector(state => selectNewOfferStatus(state))
    // use business and offer data from redux store
    const currentBusiness = useSelector((state) => selectCurrentBusiness(state))
    const newOfferData = useSelector((state) => selectNewOffer(state))
    const pendingMedia = useSelector((state) => selectPendingMedia(state))

    const newOffer = {
        ...newOfferData,
        business: currentBusiness,
        media: [{url: pendingMedia}]
    }

    // ui elements
    const handleDiscardButtonClick = withAnalytics(
        'dashboards.business.create_offer.discard_offer_btn_clicked',
        () => {
            if (status === OFFER_STATUS.CHANGED) {
                setIsSHowModal(true)
            } else {
                navigate(-1)
            }
        })

    const [nextButtonAnalyticEventName, setNextButtonAnalyticEventName] = useState('')
    const [backButtonAnalyticEventName, setBackButtonAnalyticEventName] = useState('')
    const scheduleAnalyticsEvents = {
        'timeframe_start_field_clicked': withAnalytics('dashboards.business.create_offer.schedule_page.timeframe_start_field_clicked'),
        'timeframe_end_field_clicked': withAnalytics('dashboards.business.create_offer.schedule_page.timeframe_end_field_clicked'),
        'no_schedule_btn_clicked': withAnalytics('dashboards.business.create_offer.schedule_page.no_schedule_btn_clicked'),
        'preset_schedule_btn_clicked': withAnalytics('dashboards.business.create_offer.schedule_page.preset_schedule_btn_clicked'),
        'preset_schedule_field_clicked': withAnalytics('dashboards.business.create_offer.schedule_page.preset_schedule_field_clicked'),
        'preset_schedule_dropdown_item_clicked': withAnalytics('dashboards.business.create_offer.schedule_page.preset_schedule_dropdown_item_clicked'),
        'custom_schedule_btn_clicked': withAnalytics('dashboards.business.create_offer.schedule_page.custom_schedule_btn_clicked'),
        'custom_schedule_hours_day_btn_clicked': withAnalytics('dashboards.business.create_offer.schedule_page.custom_schedule_hours_day_btn_clicked'),
        'hours_add_range_btn_clicked': withAnalytics('dashboards.business.create_offer.schedule_page.custom_schedule_hours_add_range_btn_clicked'),
        'hours_remove_range_btn_clicked': withAnalytics('dashboards.business.create_offer.schedule_page.custom_schedule_hours_remove_range_btn_clicked'),
        'hours_from_field_clicked': withAnalytics('dashboards.business.create_offer.schedule_page.custom_schedule_hours_from_field_clicked'),
        'hours_from_dropdown_item_clicked': withAnalytics('dashboards.business.create_offer.schedule_page.custom_schedule_hours_from_dropdown_item_clicked'),
        'hours_to_field_clicked': withAnalytics('dashboards.business.create_offer.schedule_page.custom_schedule_hours_to_field_clicked'),
        'hours_to_dropdown_item_clicked': withAnalytics('dashboards.business.create_offer.schedule_page.custom_schedule_hours_to_dropdown_item_clicked'),
    }

    const infoAnalyticsEvents = {
        'image_dropzone_clicked': withAnalytics('dashboards.business.create_offer.info_page.image_dropzone_clicked'),
        'image_dropzone_drag_n_drop': withAnalytics('dashboards.business.create_offer.info_page.image_dropzone_drag_n_drop'),
        'image_dropzone_delete_btn_clicked': withAnalytics('dashboards.business.create_offer.info_page.image_dropzone_delete_btn_clicked'),
    }

    useEffect(()=> {
        switch (currentStepNumber) {
            case 1:
                setNextButtonAnalyticEventName( 'dashboards.business.create_offer.info_page.continue_btn_clicked')
                setBackButtonAnalyticEventName('')
               break
            case 2:
                setNextButtonAnalyticEventName('dashboards.business.create_offer.schedule_page.continue_btn_clicked')
                setBackButtonAnalyticEventName('dashboards.business.create_offer.schedule_page.back_btn_clicked')
                break
            case 3:
                setNextButtonAnalyticEventName('dashboards.business.create_offer.pricing_page.continue_btn_clicked')
                setBackButtonAnalyticEventName('dashboards.business.create_offer.pricing_page.back_btn_clicked')
               break
            case 4:
                setNextButtonAnalyticEventName( 'dashboards.business.create_offer.settings_page.save_and_launch_offer_btn_clicked')
                setBackButtonAnalyticEventName( 'dashboards.business.create_offer.settings_page.back_btn_clicked')
               break
            default:
                setNextButtonAnalyticEventName('')
                setBackButtonAnalyticEventName('')
                console.debug(`wrong step number ${currentStepNumber}`)
                return undefined
        }
    }, [currentStepNumber])

    const getCurrentStepComponent = (stepNumber) => {
        switch (stepNumber) {
            case 1:
                return <AnalyticsContext.Provider value={infoAnalyticsEvents}>
                    <OfferInfo
                        offer={newOfferData}
                        media={pendingMedia}
                        updateMedia={updatedPendingMedia}
                        updateOffer={updatedNewOfferData}
                    />
                </AnalyticsContext.Provider>
            case 2:
                return <AnalyticsContext.Provider value={scheduleAnalyticsEvents}>
                    <OfferSchedule
                        offer={newOfferData}
                        updateOffer={updatedNewOfferData}
                    />
                </AnalyticsContext.Provider>
            case 3:
                return <OfferPricing
                    offer={newOfferData}
                    updateOffer={updatedNewOfferData}
                />
            case 4:
                return <OfferSettings
                    offer={newOfferData}
                    updateOffer={updatedNewOfferData}
                />
            default:
                console.debug(`wrong step number ${stepNumber}`)
                return undefined
        }
    }

    const handleNextButtonClick = withAnalytics(
        nextButtonAnalyticEventName,
        () => {
            if (currentStepNumber < 4) {
                setCurrentStepNumber(currentStepNumber + 1)
                return
            }
            // last step => upload media and offer
            setIsUploading(true)
            dispatch(
                createOffer({
                    mediaURI: pendingMedia,
                    businessId: currentBusiness.id,
                    title: newOfferData.title,
                    text: newOfferData.text,
                    coordinates: newOfferData.coordinates ? [newOfferData.coordinates?.[1], newOfferData.coordinates?.[0]] : undefined,
                    price: newOfferData.price,
                    discount: newOfferData.discount,
                    currency: newOfferData.currency,
                    decimals: newOfferData.decimals,
                    startTime: newOfferData.startTime,
                    endTime: newOfferData.endTime,
                    tags: newOfferData.tags,
                    points: newOfferData.points,
                    claimsLimit: newOfferData.claimsLimit,
                    claimsPerUserLimit: newOfferData.claimsPerUserLimit,
                    canBeClaimedAtLocation: newOfferData.canBeClaimedAtLocation,
                    hours: newOfferData?.customHours?.length !== 0 ? newOfferData.customHours : newOfferData.hours,
                    isActive: true,
                })
            )
                .then((res) => {
                    setIsUploading(false)
                    console.debug('create offer dispatched thunk returned result: ', res)
                    props.onOfferCreated()
                })
                .catch((err) => {
                    setIsUploading(false)
                    console.debug('create offer dispatched thunk returned error: ', err)
                })
        })

    const nextButtonIsActive = (
        stepNumber,
        offerData,
        pendingMedia,
        isUploading
    ) => {
        switch (stepNumber) {
            case 1:
                // validate title and media
                return offerData && offerData.title && offerData.text && pendingMedia
            case 2:
                // validate schedule
                return offerData && offerData.hours && (offerData.hours.length > 0 || offerData?.customHours.length > 0)
            case 3:
                // validate pricing
                return offerData && offerData.price
            case 4:
                // validate settings
                const isValidClaimsLimit = !offerData.claimsLimit || offerData.claimsLimit > 0
                const isValidClaimsPerUserLimit =
                    !offerData.claimsPerUserLimit || offerData.claimsPerUserLimit > 0
                return (
                    offerData &&
                    isValidClaimsLimit &&
                    isValidClaimsPerUserLimit &&
                    !isUploading
                )
            default:
                console.debug(
                    "can't determine nextButtonIsActive - wrong step number: " +
                    stepNumber
                )
                return false
        }
    }
    const getNextButtonTitle = (stepNumber) => {
        switch (stepNumber) {
            case 1:
                return 'Select your launch & schedule'
            case 2:
                return 'Select your pricing'
            case 3:
                return 'Select your settings'
            case 4:
                return 'Save and launch offer'
            default:
                return 'Next'
        }
    }
    // back button
    const handleBackButtonClick = withAnalytics(
        backButtonAnalyticEventName,
        () => {
            setCurrentStepNumber(currentStepNumber - 1)
        })

    const backButtonIsVisible = (stepNumber) => stepNumber > 1
    const getBackButtonTitle = (stepNumber) => {
        switch (stepNumber) {
            case 2:
                return 'Back to Name & Image'
            case 3:
                return 'Back to Launch & Schedule'
            case 4:
                return 'Back to Pricing'
            default:
                return ''
        }
    }

    const handleDiscardOfferClick = withAnalytics(
        'dashboards.business.create_offer.discard_offer_popup.discard_btn_clicked',
        props.discardOfferClicked
    )

    const handleCancelModalClick = withAnalytics(
        'dashboards.business.create_offer.discard_offer_popup.do_not_discard_btn_clicked',
        () => {
            setIsSHowModal(false)
        })

    return (
        isUploading
            ? <FullscreenPreloader/>
            : <>
                <DiscardOfferModal
                    discardOfferClicked={handleDiscardOfferClick}
                    isOpen={isShowModal}
                    onCancel={handleCancelModalClick}
                    offerTitle={newOfferData?.title}
                />
                <div className={classes.CreateOfferContainer}>

                    <BackButton onClick={handleDiscardButtonClick}>
                        Discard new offer
                    </BackButton>
                    <p className={classes.Title}>What's your offer about?</p>
                    <StepsOverview currentStep={currentStepNumber}/>
                    <div className={classes.ComponentAndPreview}>
                        <div style={{ display: "flex", flexDirection: "column"}}>
                            {getCurrentStepComponent(currentStepNumber)}
                        </div>
                        <OfferPreview offer={newOffer}/>
                        <div className={classes.ButtonsContainer}>
                            {
                                backButtonIsVisible(currentStepNumber) &&
                                <BackButton onClick={handleBackButtonClick}>
                                    {getBackButtonTitle(currentStepNumber)}
                                </BackButton>
                            }
                            <Button
                                className={classes.NextButton}
                                onClick={handleNextButtonClick}
                                disabled={!nextButtonIsActive(currentStepNumber, newOfferData, pendingMedia, isUploading)}
                            >
                                {getNextButtonTitle(currentStepNumber)}
                            </Button>
                        </div>
                    </div>
                </div>
            </>
    )
}

export default CreateOffer
