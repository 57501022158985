import {createMessage, MESSAGES_TYPES} from "../../../../submodules/naoo-web-components/Components/Message/Message";
import {Link} from "react-router-dom";
import classes from "../../../main/Posts/GoogleSheetModal/GoogleSheetModal.module.scss";
import React from "react";
import {IMPORT_ERROR_TYPES} from "../../constants";
import {validateStructure} from "./commonUtils";

export const voucherImportErrorMessages = {
    [IMPORT_ERROR_TYPES.FILE_EMPTY]: createMessage({
        children: <span>The file is empty. <Link className={classes.Link} to="/data/vouchers.csv" target="_blank"
                                                 download>Download the template</Link>, fill in the data and upload again</span>,
        type: MESSAGES_TYPES.ERROR,
        className: classes.Message
    }),
    [IMPORT_ERROR_TYPES.INVALID_TABLE_FORMAT]: createMessage({
        children: <span>Invalid table format. <Link className={classes.Link} to="/data/vouchers.csv" target="_blank"
                                                    download>Download the template</Link>, fill in the data and upload again</span>,
        type: MESSAGES_TYPES.ERROR,
        className: classes.Message
    }),
}

export const validateVoucherStructure = (data) => {
    const columnsNames = ['id', 'mediaUrl', 'idInCrm']
    return validateStructure(data, columnsNames)
}