import React, {Fragment, useEffect, useState} from 'react';
import classes from './Vouchers.module.scss'
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteVoucherThunk,
    fetchVouchers,
    fetchVouchersByNextToken,
    selectActiveSample,
    selectFetching,
    selectNextToken,
    selectVouchers,
    setFetching,
    setStatus
} from "../../../../store/reducers/vouchersReducer";
import PageTitle from "../../../components/PageTitle/PageTitle";
import {useObserver} from "../../../../submodules/naoo-web-components/Shared/hooks/useObserver";
import {returnObservableItem} from "../../../../submodules/naoo-web-components/Shared/utility/utils";
import {Preloader} from "../../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import spinnerClasses from "../../../Spinner.module.scss";
import TableWrapper from "../../../components/Table/TableWrapper/TableWrapper";
import TableHeader from "../../../components/Table/TableHeader/TableHeader";
import TableItem from "../../../components/Table/TableItem/TableItem";
import {
    BackButton,
    PlusButton,
    TextButton
} from "../../../../submodules/naoo-web-components/Components/Button/Buttons";
import MoreDropdown from "../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import MoreItem from "../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";

const Vouchers = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const vouchers = useSelector(selectVouchers)
    const sample = useSelector(selectActiveSample)
    const nextToken = useSelector(selectNextToken)
    const fetching = useSelector(selectFetching)
    const [element, setElement] = useState(null)

    const LIMIT = 10

    const handleBackButtonClick = () => {
        navigate(-1)
    }

    const handleEditClick = () => {
        dispatch(setStatus(null))
        navigate(`/vouchers/options/${sample?.id}/update`)
    }

    const handleAddNewVoucherClick = () => {
        dispatch(setStatus(null))
        navigate(`/vouchers/options/${sample?.id}/add-vouchers`)
    }

    useEffect(()=>{
        dispatch(fetchVouchers({
            sampleId: sample?.id
        }))
    },[dispatch, sample?.id])

    useObserver(
        element,
        fetching, setFetching,
        nextToken, fetchVouchersByNextToken
    )

    const handleMoreDeleteClick = (voucher) => () => {
        dispatch(deleteVoucherThunk(voucher.id))
    }

    return (
        <div className={classes.Container}>
            <div className={classes.ButtonsContainer}>
                <BackButton onClick={handleBackButtonClick}>Back to vouchers</BackButton>
                <TextButton onClick={handleEditClick}>Edit voucher sample</TextButton>
            </div>
            <div className={classes.LogoAndTitle}>
                {
                    sample?.media &&
                    <img className={classes.Logo} src={sample.media.url} alt=""/>
                }
                <PageTitle title={sample?.title}/>
            </div>

            <TableWrapper>
                <div className={classes.ContainerHeader}>
                    <div className={classes.Title}>
                        <span>Vouchers</span>
                    </div>
                    {/*<CheckBox/>*/}
                    {/*<div className={classes.Label}>*/}
                    {/*    <span>Show claimed only</span>*/}
                    {/*</div>*/}
                    <PlusButton className={classes.AddNewVouchers} onClick={handleAddNewVoucherClick}>Add new vouchers</PlusButton>
                </div>

                <TableHeader className={classes.Header}>
                    <div className={classes.Media}>
                        <span>Media</span>
                    </div>
                    <div className={classes.Id}>
                        <span>Id</span>
                    </div>
                    <div className={classes.Claimed}>
                        <span>Claimed</span>
                    </div>
                </TableHeader>
                {
                    vouchers?.map((item, index, array) => {
                        const claimedUser = item?.claimed
                            ? `${item.claimed.user.firstname} ${item.claimed.user.lastname}`
                            : null
                        return (
                            <Fragment key={item.id}>
                                <TableItem className={classes.Item}>
                                    {
                                        item?.media &&
                                        <img className={classes.Qr} src={item?.media?.url} alt=""/>
                                    }
                                    <div className={classes.Media}>
                                        <span>{item?.media?.url || '-'}</span>
                                    </div>
                                    <div className={classes.Id}>
                                        <div>
                                            <span>Naoo: {item?.id || '-'}</span>
                                        </div>
                                        <div className={classes.CrmId}>
                                            <span>CRM: {item?.idInCrm || '-'}</span>
                                        </div>
                                    </div>
                                    <div className={classes.Claimed}>
                                        <div><span>{item?.claimed?.date}</span></div>
                                        <div><span>{claimedUser}</span></div>
                                    </div>
                                    <div className={classes.More}>
                                        <MoreDropdown>
                                            <MoreItem text={'Delete'} danger onClick={handleMoreDeleteClick(item)}/>
                                        </MoreDropdown>
                                    </div>
                                </TableItem>
                                {
                                    returnObservableItem(array.length, LIMIT, index, setElement)
                                }
                            </Fragment>
                        )
                    })
                }
                {
                    fetching && <Preloader className={spinnerClasses.paginationSpinnerSize}/>
                }
            </TableWrapper>
        </div>
    );
};

export default Vouchers;