import React, {useEffect, useState} from 'react';
import {BackButton, Button} from "../../../../submodules/naoo-web-components/Components/Button/Buttons";
import classes from "./VoucherSampleForm.module.scss";
import PageTitle from "../../../components/PageTitle/PageTitle";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Input from "../../../../submodules/naoo-web-components/Components/Input/Input";
import {ReactComponent as NaooIcon} from "../../../../submodules/naoo-web-components/Shared/UI/assets/naooIcon.svg";
import {handleErrors, validateNumberInput} from "../../../../submodules/naoo-web-components/Shared/utility/utils";
import Margin from "../../../../submodules/naoo-web-components/Components/Margin/Margin";
import Dropdown from "../../../../submodules/naoo-web-components/Components/Dropdown/Dropdown";
import Switch from "../../../../submodules/naoo-web-components/Components/Switch/Switch";
import Preview from "./Preview/Preview";
import {
    createVoucherSampleThunk, selectActiveSample, selectStatus, setActiveVoucher, setStatus,
    updateVoucherSampleThunk
} from "../../../../store/reducers/vouchersReducer";
import {selectCurrentBusinessId} from "../../../../store/reducers/managedBusinessesSlice";
import DatePicker from "../../../../submodules/naoo-web-components/Components/Date/DatePicker/DatePicker";
import Dropzone from "../../../../submodules/naoo-web-components/Components/Dropzone/Dropzone";


export const VOUCHER_TYPES = {
    'QR code': 'qr',
    'Barcode': 'barcode',
    'Numeric': 'numeric',
    'PDF file': 'pdf',
}

const getKeyFromTypeOfVoucher = (name) => {
    for (const key in VOUCHER_TYPES) {
        if (VOUCHER_TYPES[key] === name) return key
    }
    return null
}

const VoucherSampleForm = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const voucherSample = useSelector(selectActiveSample)
    const businessId = useSelector(selectCurrentBusinessId)
    const status = useSelector(selectStatus)

    const [title, setTitle] = useState(voucherSample?.title || '')
    const [description, setDescription] = useState(voucherSample?.description || '')
    const [points, setPoints] = useState(voucherSample?.priceInPoints || '')
    const [publicationDate, setPublicationDate] = useState(voucherSample?.publicationDate ? new Date(voucherSample?.publicationDate) : null)
    const [startDate, setStartDate] = useState(voucherSample?.startDate ? new Date(voucherSample?.startDate) : null)
    const [endDate, setEndDate] = useState(voucherSample?.endDate ? new Date(voucherSample?.endDate) : null)
    const [isActive, setIsActive] = useState(voucherSample?.isActive || false)
    const [media, setMedia] = useState(voucherSample?.media || null)
    const [voucherType, setVoucherType] = useState(voucherSample?.voucherType || '')
    const [voucherTypeLabel, setVoucherTypeLabel] = useState(getKeyFromTypeOfVoucher(voucherType) || '')

    const errors = {
        title: title === '',
        points: points === '',
        media: !media
    }

    const handleTitleChange = (event) => {
        setTitle(event.target.value)
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value)
    }

    const handlePointsChange = (event) => {
        const isValidInput = validateNumberInput(event)
        if (isValidInput) {
            setPoints(event.target.value)
        }
    }

    const handleMediaChange = (media) => {
        setMedia({
            url: URL.createObjectURL(media[0])
        })
    }

    const handleMediaClear = () => {
        setMedia(null)
    }

    const handleIsActiveChange = (event) => {
        setIsActive(event.target.checked)
    }

    const handlePublicationDateChange = (date) => {
        setPublicationDate(date)
    }

    const handleStartDateChange = (date) => {
        setStartDate(date)
    }

    const handleEndDateChange = (date) => {
        setEndDate(date)
    }

    const handleVoucherTypeContentChange = (type) => {
        setVoucherType(VOUCHER_TYPES[type])
        setVoucherTypeLabel(type)
    }

    const handleSubmitForm = () => {
        const data = {
            title,
            description,
            businessId,
            voucherType,
            media,
            priceInPoints: +points,
            isActive,
            publicationDate: publicationDate?.toISOString(),
            startDate: startDate?.toISOString(),
            endDate: endDate?.toISOString(),
        }

        if (voucherSample) {
            dispatch(updateVoucherSampleThunk({
                sample: data,
                id: voucherSample?.id
            }))
        } else {
            dispatch(createVoucherSampleThunk(data))
        }
    }

    const maxDateForPublication = startDate || endDate

    useEffect(() => {
        if (status === 'successfully') {
            dispatch(setStatus(null))
            dispatch(setActiveVoucher(null))
            navigate(-1)
        }
    }, [status, dispatch, navigate])

    return (
        <div className={classes.Container}>
            <BackButton onClick={() => navigate(-1)}>
                Discard voucher sample
            </BackButton>
            <div className={classes.TitleContainer}>
                <PageTitle title={voucherSample ? 'Edit voucher sample' : 'New voucher sample'}/>
            </div>

            <div className={classes.FormContainer}>
                <div className={classes.MainContainer}>
                    <div className={classes.Section}>
                        <div className={classes.Title}>
                            <span>Main</span>
                        </div>

                        <div className={classes.InputContainer}>
                            <Input
                                label={'Voucher title'}
                                placeholder={'Voucher title'}
                                name={'title'}
                                value={title}
                                onChange={handleTitleChange}
                                maxLength={128}
                                error={errors.title}
                            />
                        </div>
                        <div className={classes.InputContainer}>
                            <Input
                                label={'Description'}
                                placeholder={'Voucher description'}
                                name={'description'}
                                value={description}
                                onChange={handleDescriptionChange}
                            />
                        </div>
                        <div className={classes.InputContainer}>
                            <Input
                                label={'Points amount'}
                                placeholder={'Points amount'}
                                name={'pAmount'}
                                value={points}
                                onChange={handlePointsChange}
                                Icon={NaooIcon}
                                error={errors.points}
                            />
                        </div>
                    </div>
                    <Margin top={24}/>
                    <div className={classes.Section}>
                        <div className={classes.Title}>
                            <span>Settings</span>
                        </div>

                        <div className={classes.InputContainer}>
                            <div className={classes.Label}>
                                <span>Publication date</span>
                            </div>
                            <DatePicker
                                className={classes.ScheduleInput}
                                placeholder={'Choose publication date'}
                                value={publicationDate}
                                onChange={handlePublicationDateChange}
                                format={'dd MMM yyyy'}
                                maxDate={maxDateForPublication}
                            />
                        </div>
                        <div className={classes.InputContainer}>
                            <div className={classes.Label}>
                                <span>Start date</span>
                            </div>
                            <DatePicker
                                className={classes.ScheduleInput}
                                placeholder={'Choose start date'}
                                value={startDate}
                                onChange={handleStartDateChange}
                                format={'dd MMM yyyy'}
                                minDate={publicationDate}
                                maxDate={endDate}
                            />
                        </div>
                        <div className={classes.InputContainer}>
                            <div className={classes.Label}>
                                <span>End date</span>
                            </div>
                            <DatePicker
                                className={classes.ScheduleInput}
                                placeholder={'Choose end date'}
                                value={endDate}
                                onChange={handleEndDateChange}
                                format={'dd MMM yyyy'}
                                minDate={startDate}
                            />
                        </div>
                        <div className={`${classes.Label} ${classes.IsActive}`}>
                            <span>Is active</span>
                            <Switch checked={isActive} onChange={handleIsActiveChange}/>
                        </div>
                    </div>
                    <Margin top={24}/>
                    <div className={classes.Section}>
                        <div className={classes.Title}>
                            <span>Appearance</span>
                        </div>
                        <div className={classes.Label}>
                            <span>Add images</span> <span className={classes.Gray}>(optional)</span>
                        </div>
                        <Dropzone
                            media={media?.url}
                            onChange={handleMediaChange}
                            className={classes.ImageDropZone}
                            onClear={handleMediaClear}
                        />
                    </div>
                    {
                        !voucherSample &&
                        <>
                            <Margin top={24}/>
                            <div className={classes.Section}>
                                <div className={classes.Title}>
                                    <span>Voucher type</span>
                                </div>
                                <Dropdown
                                    className={classes.Dropdown}
                                    placeholder={'Select voucher type'}
                                    options={Object.keys(VOUCHER_TYPES)}
                                    value={voucherTypeLabel}
                                    onChange={handleVoucherTypeContentChange}
                                    isClearable={true}
                                />
                            </div>
                        </>
                    }
                </div>
                <div className={classes.PreviewContainer}>
                    <div className={classes.Section}>
                        <div className={classes.Title}>
                            <span>Preview</span>
                        </div>
                        <Preview
                            title={title}
                            media={media}
                            voucherType={voucherType}
                        />
                    </div>
                </div>
                <div className={classes.SaveButtonContainer}>
                    <Button
                        onClick={handleSubmitForm}
                        disabled={handleErrors(errors)}
                    >{voucherSample ? 'Save voucher sample' : 'Create voucher sample'}</Button>
                </div>
            </div>
        </div>
    );
};

export default VoucherSampleForm;