import React from 'react';
import classes from '../../AddVouchers.module.scss'
import MoreDropdown from "../../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import MoreItem from "../../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";

const PresentationMode = ({voucher, onChange, onDelete}) => {

    const handleEditClick = () => {
        onChange({...voucher, editMode: true})
    }

    const handleDeleteClick = () => {
       onDelete()
    }

    return (
        <>
            {
                voucher?.media &&
                <img
                    className={classes.Media}
                    src={voucher?.media}
                    alt=""
                />
            }
            <div className={classes.CrmId}>
                <span>{voucher?.crmId ?? '-' }</span>
            </div>
            <div className={classes.More}>
                <MoreDropdown>
                    <MoreItem text={'Edit'} onClick={handleEditClick}/>
                    <MoreItem text={'Delete'} onClick={handleDeleteClick} danger/>
                </MoreDropdown>
            </div>
        </>
    );
};

export default PresentationMode;