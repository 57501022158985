import {createRequest} from "../submodules/naoo-web-components/Api/createRequest";
import {generateUrlWithQueryParams} from "../submodules/naoo-web-components/Api/generateUrlWithQueryParams/generateUrlWithQueryParams";

// === MAIN API ===
export const mainGetVersion = () =>
  createRequest('main', 'get', 'version', null)

export const mainGetBusinessDashboard = (
  businessId,
  timeframeStart,
  timeframeEnd
) => {

  const url = generateUrlWithQueryParams(`businesses/${businessId}/dashboard`,{
    startTime: timeframeStart,
    endTime: timeframeEnd
  })
  return createRequest('main', 'get', url, null)
}

//Offers
export const mainCreateOffer = (
  businessId,
  title,
  text,
  media,
  latitude,
  longitude,
  price,
  discount,
  currency,
  decimals,
  startTime,
  endTime,
  tags,
  points,
  claimsLimit,
  claimsPerUserLimit,
  canBeClaimedAtLocation,
  hours,
  isActive,
  coordinates
) => {
  let offerData = {
    businessId,
    title,
    text,
    mediaIds:[media],
    latitude,
    longitude,
    price,
    discount,
    currency,
    decimals,
    startTime,
    endTime,
    tagIds: tags?.map(tag => tag.id) ?? [],
    points,
    claimsLimit,
    claimsPerUserLimit,
    canBeClaimedAtLocation,
    hours,
    isActive,
    coordinates
  }
  if(!offerData.points){
    offerData.points = offerData.price/(10**offerData.decimals)
  }
  console.debug(`[api.js] offer data: `, offerData)
  return createRequest('main', 'post', 'offers', offerData)
}

export const mainUpdateOffer = (
  offerId,
  title,
  text,
  media,
  price,
  discount,
  decimals,
  startTime,
  endTime,
  tags,
  points,
  claimsLimit,
  claimsPerUserLimit,
  canBeClaimedAtLocation,
  hours,
  isActive,
  coordinates
) => {
  let offerData = {
    title,
    text,
    media,
    price,
    discount,
    decimals,
    startTime,
    endTime,
    tags,
    points,
    claimsLimit,
    claimsPerUserLimit,
    canBeClaimedAtLocation,
    hours,
    isActive,
    coordinates
  }

  console.debug(`[api.js] update offer data: `, offerData)
  return createRequest('main', 'patch', `offers/${offerId}`, offerData)
}

export const mainGetOfferById = (offerId) => {
  console.debug(`[api.js] get offer  ${offerId}`)
  return createRequest('main', 'get', `/offers/${offerId}`,null)
}

export const mainDeleteOffer = (offerId) => {
  console.debug(`[api.js] delete offer ${offerId}`)
  return createRequest('main', 'delete', `offers/${offerId}`, null)
}

export const getOffersStats = ({startTime, endTime }) => {
  let url = `/offers?getStatsStartDate=${startTime}&getStatsEndDate=${endTime}`
  return createRequest('main', 'get', url, null)
}
export const getOfferStats = ({id, startTime, endTime }) => {
  const url = generateUrlWithQueryParams(`/offers/${id}/stats_history`,{
    startTime,
    endTime
  })
  return createRequest('main', 'get', url , null)
}

export const createOfferLike = (offerId) =>
    createRequest('main', 'post', `/offers/${offerId}/likes`, null)

export const deleteOfferLike = (offerId) =>
    createRequest('main', 'delete', `/offers/${offerId}/likes`, null)


export const getOfferComments = ({id, nextToken}) =>{
  if (nextToken) return createRequest('main', 'get', `/offers/${id}/comments?nextToken=${nextToken}`, null)

  return createRequest('main', 'get', `/offers/${id}/comments`, null)
}

export const createOfferComment = ({id, comment, bid, uid}) =>
    createRequest('main', 'post', `/offers/${id}/comments`, comment )

export const createOfferLikeForComment = ({id, cid}) =>
    createRequest('main', 'post', `/offers/${id}/comments/${cid}/likes`, null)

export const deleteOfferLikeForComment = ({id, cid}) =>
    createRequest('main', 'delete', `/offers/${id}/comments/${cid}/likes`, null)

//Offers

// === BUSINESS API ===
export const mainGetBusinessCategories = () => {
  console.debug(`[api.js] get business categories `)
  return createRequest('main', 'get', `/business_categories`,null)
}

export const mainCreateBusiness = (business)=>{
  console.debug(`[api.js] create business `)
  return createRequest('main', 'post', '/businesses', business)
}

export const mainUpdateBusiness = (business, businessId)=>{
  console.debug(`[api.js] update business `)
  return createRequest('main', 'patch',`/businesses/${business.id ?? businessId}`,business)
}

export const mainCreateBusinessProfileMedia = (businessId,media)=>{
  return createRequest('main', 'post',`/businesses/${businessId}/profile_media`,media)
}

export const mainDeleteBusinessProfileMedia = (businessId,mediaId)=>{
  return createRequest('main', 'delete',`/businesses/${businessId}/profile_media/${mediaId}`)
}

export const getBusinessById = (businessId) =>{
  return createRequest('main', 'get', `/businesses/${businessId}`, null)
}

export const deleteBusiness = (id)=>
    createRequest('main', 'delete', `/businesses/${id}`, null )

export const getBusinesses = ({bl, tr, excludeNoDevice, q, nextToken, excludeNotOwned, limit}) => {
  const url = generateUrlWithQueryParams('/businesses', {
    q,
    bl,
    tr,
    excludeNoDevice,
    excludeNotOwned,
    limit,
    nextToken,
  })

  return createRequest('main', 'get', url, null)
}
// === BUSINESS API ===

export const mainCreateMediaPresignedUrl = (contentType, dimensions) =>
  createRequest('main', 'post', 'media', { contentType, dimensions })

export const mainGetBusinessesExcludeNotOwnedByCurrentUser = () =>
  createRequest('main', 'get', 'businesses?excludeNotOwned=true', null)

export const mainGetMedia = (mediaId) =>
  createRequest('main', 'get', `media/${mediaId}`, null)

// === ADMIN API ===
export const adminGetVersion = () =>
  createRequest('admin', 'get', 'version', null)


//TAGS
export const getTags = ({q, followedByUserWithID, limit, offset}) =>{
  const url = generateUrlWithQueryParams('/tags', {
    q,
    offset,
    limit,
    followedByUserWithID,
  })

  return createRequest('main', 'get', url, null)
}


//TAGS

//POSTS
export const getPosts = (params) => {
  return createRequest('main', 'get', '/posts', null, params)
}

export const createPost = (post) =>
    createRequest('main', 'post', '/posts', post )

export const getPost = (postId) =>
    createRequest('main', 'get', `/posts/${postId}`, null )

export const updatePost = (post, postId) =>
    createRequest('main', 'patch', `/posts/${postId}`, post)

export const deletePost = (postId) =>
    createRequest('main', 'delete', `/posts/${postId}`, null)

export const createLike = (postId) =>
    createRequest('main', 'post', `/posts/${postId}/likes`, null)

export const deleteLike = (postId) =>
    createRequest('main', 'delete', `/posts/${postId}/likes`, null)


export const getComments = ({id, nextToken}) =>{

  const url = generateUrlWithQueryParams(`/posts/${id}/comments`,{
    nextToken
  })
  return createRequest('main', 'get', url, null)
}

export const createComment = ({id, comment, bid, uid}) =>
    createRequest('main', 'post', `/posts/${id}/comments`, comment )

export const createLikeForComment = ({id, cid}) =>
    createRequest('main', 'post', `/posts/${id}/comments/${cid}/likes`, null)

export const deleteLikeForComment = ({id, cid}) =>
    createRequest('main', 'delete', `/posts/${id}/comments/${cid}/likes`, null)

export const getPostStats = ({id, startTime, endTime }) => {
  const url = generateUrlWithQueryParams(`/posts/${id}/stats_history`, {
    startTime,
    endTime
  })
  return createRequest('main', 'get', url , null)
}

//POSTS

//USERS
export const getUsers = ({limit, q, nextToken})=>{
  const url = generateUrlWithQueryParams('/users', {
    limit,
    q,
    nextToken
  })
  return  createRequest('main', 'get', url,null)
}

export const getFollowers = ({followingBusinessWithId, startTime, nextToken}) => {
   return createRequest('main', 'get', `/users`,null, {
     followingBusinessWithId,
       startTime,
     nextToken
   })
}
//USERS

//CHANNELS

export const getChannels = ({nextToken})=>{
  const url = generateUrlWithQueryParams('/channels', {
    nextToken
  })
  return createRequest('main', 'get', url, null)
}

export const getChannel = (id)=>
    createRequest('main', 'get', `/channels/${id}`, null)

export const createChannel = (channel)=>
    createRequest('main', 'post', '/channels', channel)

export const updateChannel = ({channel, id})=>
    createRequest('main', 'patch', `/channels/${id}`, channel)

export const createUserChannelRole = (data)=>
    createRequest('main', 'post', '/user_channel_roles', data)

export const getUserChannelRoles = (channelId) =>{
  const url = generateUrlWithQueryParams('/user_channel_roles',{
    channelId,
  })
  return createRequest('main', 'get', url, null)
}


export const deleteUserChannelRoles = (id) =>
    createRequest('main', 'delete', `/user_channel_roles/${id}`)
//CHANNELS

//VOUCHERS
export const getVoucherSamples = ({businessId, nextToken})=>{
  const url = generateUrlWithQueryParams('/third_party_voucher_samples', {
    businessId,
    nextToken
  })
  return createRequest('main', 'get', url, null)
}

export const getVoucherSample = (id) =>
    createRequest('main', 'get', `/third_party_voucher_samples/${id}`, null)

export const createVoucherSample = (sample)=>
    createRequest('main', 'post', '/third_party_voucher_samples', sample)

export const updateVoucherSample = ({sample, id})=>
    createRequest('main', 'patch', `/third_party_voucher_samples/${id}`, sample)

export const deleteVoucherSample = (id)=>
    createRequest('main', 'delete', `/third_party_voucher_samples/${id}`)


export const getVouchers = ({sampleId, nextToken})=>{
  const url = generateUrlWithQueryParams('/third_party_vouchers', {
    sampleId,
    nextToken
  })
  return createRequest('main', 'get', url, null)
}

export const createVouchers = (vouchers)=>
    createRequest('main', 'post', '/third_party_vouchers', vouchers)

export const updateVoucher = ({data, id})=>
    createRequest('main', 'patch', `/third_party_vouchers/${id}`, data)

export const deleteVoucher = (id)=>
    createRequest('main', 'delete', `/third_party_vouchers/${id}`)

//VOUCHERS

//Media
export const getMedia = (mediaId) =>
    createRequest('main', 'get', `media/${mediaId}`, null)

export const createMediaPresignedUrl = (contentType, dimensions) =>
    createRequest('main', 'post', 'media', {contentType, dimensions})
//Media

//Chat
export const getSendbirdSessionToken = () =>
    createRequest('main', 'get', '/sendbird_session_token', null)
//Chat