import React from 'react';
import classes from './SampleVoucher.module.scss'
import {ReactComponent as NaooIcon} from "../../../../submodules/naoo-web-components/Shared/UI/assets/naooIcon.svg";
import {numberWithSpaces} from "../../../shared/utility/numberWithSpaces";

const SampleVoucher = ({sampleVoucher, className, onClick, MoreDropdown}) => {
    const {
        color, title, priceInPoints,
        isActive,
        media
    } = sampleVoucher

    const bgCardColor = `#${color}`

    return (
        <div
            className={`${classes.Container} ${className} ${!!media ? classes.WithMedia : ''}`}
            style={{
                backgroundColor: bgCardColor
            }}
            onClick={onClick}
        >
            {
                !isActive &&
                <div className={classes.NotActive}/>
            }

            {
                !!media && (
                    <>
                        <img className={classes.Media} src={media?.url} alt=""/>
                        <div className={classes.Shade}/>
                    </>
                )
            }
            <div className={classes.MoreContainer}>
                {MoreDropdown}
            </div>
            <div className={'Title'}>
                <span>{title}</span>
            </div>

            <div className={'Subtitle'}>
                {/*<span>{numberWithSpaces(vouchers?.length || 0)} vouchers</span>*/}
            </div>

            <div className={'Footer'}>
                {
                    priceInPoints &&
                    <div className={'Points'}>
                        <span>{numberWithSpaces(priceInPoints)}</span>
                        <NaooIcon width={22} height={10}/>
                    </div>
                }

            </div>
        </div>
    );
};

export default SampleVoucher;